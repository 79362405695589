<script>
import { BButton, BCol, BFormGroup, BFormInput, BRow, BSpinner } from 'bootstrap-vue';
import vSelect from 'vue-select';
import TogglerBtn from '@/components/ui/TogglerBtn/TogglerBtn';
import SlideInOutAnimation from '@/components/ui/SlideInOutAnimation/SlideInOutAnimation';
import api from '@/services/api';
import AppTimeline from '@core/components/app-timeline/AppTimeline';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { getLocalVar } from '@/util/localstorage-helper';
import LogsTimeline from '@/components/LogsTimeline';
import FilterBlock from '@/components/FilterBlock';
import CoinLogsTimeline from '@/components/CoinLogsTimeline.vue';
import { sortItems } from '@/util/helper';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'CoinActivities',
    components: {
        CoinLogsTimeline,
        FilterBlock,
        LogsTimeline,
        ProgressLinear,
        AppTimelineItem,
        AppTimeline,
        SlideInOutAnimation,
        TogglerBtn,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BSpinner,
        vSelect
    },
    data: () => ({
        activityLogs: {
            items: [],
            pagination: {}
        },
        actionsList: [],
        filter: {
            page: 1,
            type: null
        },
        interval: null,
        localLang: getLocalVar('lang'),

        autoReload: true,
        isFilterDisplay: true,
        requestPending: false
    }),
    computed: {
        computedPlayPauseIcon() {
            return this.autoReload ? 'PauseIcon' : 'PlayIcon';
        }
    },
    methods: {
        async getActivity() {
            if (this.requestPending) return;
            this.requestPending = true;
            await this.checkFilter();
            this.filter.limit = 10;
            this.filter['merchant_id'] = MERCHANT_ID;
            const { data } = await api.coin.fetchTransactionList(this.filter);
            if (data.pagination.current === 1) {
                this.activityLogs.items = data.result;
            } else {
                this.activityLogs.items = [...this.activityLogs.items, ...data.result];
            }

            this.activityLogs.pagination = data.pagination;
            this.requestPending = false;
        },

        loadNextPage(page) {
            if (this.requestPending || this.autoReload || !page) return;
            this.filter.page = page;
            this.getActivity();
        },

        async getActionsList() {
            const { data } = await api.coin.fetchTransactionFilter();
            this.actionsList = sortItems(data);
            this.actionsList = this.actionsList.map((item) => {
                // return {
                //     value: item.type,
                //     name: checkLang(item.name)
                // };
                return item.type;
            });
        },

        async filterAction() {
            if (this.requestPending) return;
            this.autoReload = false;
            await this.checkFilter();
            this.requestPending = true;
            const { data } = await api.coin.fetchTransactionList(this.filter);
            window.scrollTo(0, 0);
            this.activityLogs.items = data.result;
            this.activityLogs.pagination = data.pagination;
            this.requestPending = false;
        },

        checkFilter() {
            if (this.filter) {
                Object.entries(this.filter).forEach(([key, value]) => {
                    if (key === 'phone') {
                        if (this.filter[key].replace(/\D/g, '').length < 12) return;
                        this.filter[key] = this.filter[key].replace(/\D/g, '');
                    }
                    // else if (key === 'type' && value) {
                    //     this.filter[key] = value.value;
                    // }
                    else if (!this.filter[key]) {
                        delete this.filter[key];
                    }
                });
            }
        },

        toggleAutoReload() {
            return this.autoReload ? this.stopAutoReload() : this.startAutoReload();
        },

        startAutoReload() {
            if (!this.autoReload) return this.getAuto();
            this.interval = setInterval(() => {
                this.getAuto();
            }, 2000);
        },

        async getAuto() {
            this.autoReload = true;
            if (this.requestPending) return;
            this.filter.page = 1;
            await this.getActivity();
        },

        stopAutoReload() {
            this.autoReload = false;
            this.requestPending = false;
            clearInterval(this.interval);
        },

        deSelect(val) {
            if (!val) {
                this.filterAction();
            }
        }
    },
    async mounted() {
        this.startAutoReload();
        await Promise.all([this.getActivity(), this.getActionsList()]);
    }
};
</script>

<template>
    <div>
        <div class="d-flex align-items-start">
            <h1 class="mb-2">
                {{ $t('navigation.subscribers.activity') }}
            </h1>
            <toggler-btn v-model="isFilterDisplay" class="ml-auto" />
        </div>
        <b-row>
            <b-col class="col-lg-6 col-sm-6 col-12 order-lg-1 order-sm-1 order-2">
                <coin-logs-timeline
                    v-model:loading="requestPending"
                    :items="activityLogs.items"
                    :pagination="activityLogs.pagination"
                    @loadNextPage="loadNextPage"
                >
                    <template #default="{ item }">
                        <!--                        <div>{{ item }}</div>-->
                        <div class="activity-item">
                            <div class="activity-item__content">
                                <h4>{{ item.title }}</h4>
                                <h5>
                                    {{ item.sub_title }}
                                </h5>
                                <router-link
                                    v-if="item.user"
                                    :to="{
                                        name: 'subscribers Single',
                                        params: {
                                            id: item.user.id
                                        }
                                    }"
                                    class="d-flex"
                                >
                                    {{ item.user.phone }}
                                </router-link>
                                <span :class="{ debit: item.debit }"
                                    >{{ item.amount }} {{ $t('titles.coin') }}
                                </span>
                            </div>
                            <small class="ml-auto text-right">
                                {{ item.created_at }}
                            </small>
                        </div>
                        <!--                        <router-link-->
                        <!--                            :to="{-->
                        <!--                                name: 'subscribers Single',-->
                        <!--                                params: {-->
                        <!--                                    id: item.user.id-->
                        <!--                                }-->
                        <!--                            }"-->
                        <!--                            class="d-flex"-->
                        <!--                        >-->
                        <!--                            {{ item.phone }}-->
                        <!--                        </router-link>-->
                        <!--                                                <div v-for="(data, key) in item.data" :key="key" class="d-flex">-->
                        <!--                                                    <small v-if="key === 'prize'">-->
                        <!--                                                        {{ key }}: {{ data.name[localLang] }}-->
                        <!--                                                    </small>-->
                        <!--                                                    <small v-else>{{ key }}: {{ data }}</small>-->
                        <!--                                                </div>-->
                    </template>
                </coin-logs-timeline>
            </b-col>
            <b-col class="col-lg-6 col-sm-6 col-12 order-lg-2 order-sm-2 order-1">
                <div class="filter--sticky">
                    <slide-in-out-animation v-model="isFilterDisplay">
                        <filter-block
                            v-model:filter="filter"
                            :filter="filter"
                            :loading="requestPending"
                            @submit="filterAction"
                        >
                            <b-col class="mb-2" cols="12">
                                <b-button
                                    :disabled="requestPending"
                                    variant="outline-primary"
                                    @click="toggleAutoReload"
                                >
                                    <b-spinner v-if="requestPending" small variant="primary" />
                                    <feather-icon v-else :icon="computedPlayPauseIcon" />
                                </b-button>
                            </b-col>
                            <b-col class="mb-2" cols="12">
                                <!--                                <h5>-->
                                <!--                                    {{ $t('titles.phone_number') }}-->
                                <!--                                </h5>-->
                                <!--                                <b-form-group>-->
                                <!--                                    <b-form-input-->
                                <!--                                        v-model="filter.phone"-->
                                <!--                                        v-mask="'+998 9# ### ## ##'"-->
                                <!--                                        placeholder="+998 9# ### ## ##"-->
                                <!--                                        @input="deSelect"-->
                                <!--                                    />-->
                                <!--                                </b-form-group>-->
                            </b-col>
                            <b-col class="mb-2" cols="12">
                                <h5>
                                    {{ $t('titles.action') }}
                                </h5>
                                <b-form-group>
                                    <v-select
                                        v-model="filter.type"
                                        :disabled="!actionsList.length"
                                        :menu-props="{
                                            closeOnContentClick: false
                                        }"
                                        :options="actionsList"
                                        :searchable="false"
                                        label="name"
                                        @input="deSelect"
                                    />
                                </b-form-group>
                            </b-col>
                        </filter-block>
                    </slide-in-out-animation>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.activities {
    width: 100%;
    padding: 25px 15px;
}

.activity-item {
    display: flex;
    justify-content: space-between;

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;

        h4 {
            font-weight: 600;
            font-size: 17px;
            line-height: 21px;
        }

        h5 {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
        }

        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #7367f0;
            margin-bottom: 0;
        }

        span {
            color: #ea5455;

            &.debit {
                color: #28c76f;
            }
        }
    }
}
</style>
